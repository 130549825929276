import { createPortal } from 'react-dom';
import styled from 'styled-components';

import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import SheetHeader from '@/components/layout/SheetHeader';

import { SecondaryButton } from './Buttons';
import FlexColumn from './FlexColumn';
import PageBlocker from './PageBlocker';

// Styles
const ModalSheet = styled.div`
  z-index: 200;
  pointer-events: auto;

  overflow: hidden;
  width: 512px;
  max-width: calc(100% - 24px);
  height: 100%; /* Fix for proper scrolling on Sarafi */
  min-height: 256px;
  max-height: calc(100% - 30vh);
  margin-top: 15vh;
  /* For notched devices */
  margin-bottom: env(safe-area-inset-bottom);

  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow400};
  backface-visibility: hidden;

  font-size: 18px;

  /* Smaller device adjustments */
  @media (max-height: 768px) {
    max-height: none;
    height: calc(100% - 24px);
    margin-top: 12px;
  }
`;

const ModalWrapper = styled.div`
  z-index: 10001;
  pointer-events: none;

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Mount/unmount animation */
  &.enter ${ModalSheet} {
    transform-origin: center calc(100% - 64px);

    opacity: 0;
    transform: perspective(512px) translate3D(-24px, 128px, 24px) rotateX(32deg) rotateZ(4deg);
  }
  &.enter-active ${ModalSheet} {
    pointer-events: none;
    transition:
      opacity 200ms,
      transform 250ms ${({ theme }) => theme.easeDecelerate};

    opacity: 1;
    transform: perspective(512px) translate3D(0, 0, 0) rotateX(0deg) rotateZ(0deg);
  }
  &.exit-active ${ModalSheet} {
    pointer-events: none;
    transform-origin: center 64px;
    transition:
      opacity 200ms 50ms,
      transform 250ms ${({ theme }) => theme.easeAccelerate};

    opacity: 0;
    transform: perspective(512px) translate3D(24px, 256px, 24px) rotateX(-48deg) rotateZ(4deg);
  }
`;

// Props
interface Props {
  forwardedKey?: string;
  heading?: string;
  handleClose(): void;
  children?: React.ReactNode;
  usePortal?: boolean;
}

// Component
const Modal = ({ forwardedKey, heading, handleClose, children, usePortal = false }: Props) => {
  const modalContent = (
    <>
      <PageBlocker show={!!children} onClick={handleClose} />
      <TransitionGroup component={null}>
        {!!children && (
          <CSSTransition key={forwardedKey} timeout={250}>
            <ModalWrapper>
              <ModalSheet>
                <FlexColumn>
                  {heading && (
                    <SheetHeader
                      primary
                      mainTitle={heading}
                      trailing={
                        <SecondaryButton
                          trailingIcon='close'
                          onClick={() => {
                            handleClose();
                          }}
                        />
                      }
                    />
                  )}
                  {children}
                </FlexColumn>
              </ModalSheet>
            </ModalWrapper>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );

  return usePortal
    ? createPortal(modalContent, document.getElementById('modal-portal')!)
    : modalContent;
};

export default Modal;
